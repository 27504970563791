import { Button, CircularProgress, Divider, ThemeProvider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AuthProvider from "../implementation/auth-provider";
import { useState } from "react";
import LoadingBarApp from "../implementation/loading";
import Navbar from "../implementation/navbar";
import { useMediaQuery } from "react-responsive";
import { AdminRespond, Presupuesto, PresuRespond, UserRespond } from "../classes/presupuesto";
import { doc, getDoc } from "firebase/firestore";
import { db, functions } from "../firebase/firebase";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, timelineItemClasses, TimelineSeparator } from "@mui/lab";
import { CommentOutlined, MessageOutlined, ReceiptLongOutlined, ReceiptOutlined, SendOutlined } from "@mui/icons-material";
import { theme } from "../style/theme";
import { useTranslation } from "react-i18next";
import { TextFieldMultiline } from "../implementation/textFielf";
import { httpsCallable } from "firebase/functions";

export default function BudgetView() {
    const navigate = useNavigate();

    const {t, i18n} = useTranslation();

    const {bid} = useParams();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [budgetData, setBudgetData] = useState(null);

    const [loading, setLoading] = useState(false);

    const isLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 530px)'
    });

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getBudgetData() {
        const budgetData = await getDoc(doc(db, 'users', userr.uid, 'presupuestos', bid));

        const budget = await Presupuesto.fromDataBase(budgetData);

        console.log(budget);
        setBudgetData(budget);
    };

    if (state === 1) {
        if (budgetData === null) {
            getBudgetData();

            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 className="page-title-p" style={{fontSize: smallScreen? '' : '30px'}}>
                        Presupuesto nº{bid}
                    </h1>
                    <Divider/>
                    <LoadingBarApp/>
                </div>
            </div>;
        } else {
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 className="page-title-p" style={{fontSize: smallScreen? '' : '30px'}}>
                        Presupuesto nº{budgetData.getId()}
                    </h1>
                    <Divider/>
                    <ThemeProvider theme={theme}>
                        <Timeline 
                            position="right"
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                            }}
                        >
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineConnector sx={{backgroundColor:'transparent'}}/>
                                    <TimelineDot color="primary" variant="outlined">
                                        <ReceiptOutlined />
                                    </TimelineDot>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <div style={{borderLeft:'1px solid grey', borderRadius:'5px', paddingLeft:'5px'}}>
                                        <div style={{fontSize:'22px'}}>Solicitud de presupuesto <span style={{fontSize:'18px'}}>{` (${budgetData.getDateString()})`}</span></div>
                                        <div>Presupuesto que se ha solicitado inicialmente.</div>
                                        <div className="presu-box" style={{margin:'20px'}}>
                                            {budgetData.drawFieldsContent(i18n.resolvedLanguage).map(field => {
                                                return field;
                                            })}
                                        </div>
                                        <div style={{marginLeft:'20px', marginRight:'20px', textAlign:'justify'}}>
                                            {budgetData.getObservaciones()}
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                            {budgetData.getResponsesNum() === 0
                                ? <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineConnector/>
                                        <TimelineDot color="primary" variant="outlined">
                                            <MessageOutlined />
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <div style={{borderLeft:'1px solid grey', borderRadius:'5px', paddingLeft:'5px'}}>
                                            <div style={{fontSize:'22px'}}>Sin respuestas</div>
                                            <div>Actualmente no hay respuestas para este presupuesto. Estamos trabajando en ello, en breves, alguien de nuestro equipo se pondrá en contacto con usetd..</div>
                                        </div>
                                    </TimelineContent>
                                </TimelineItem>
                                : budgetData.getResponses().map((respond, index) => {
                                    return <TimelineItem key={index}>
                                        <TimelineSeparator>
                                            <TimelineConnector/>
                                            <TimelineDot color="primary" variant="outlined">
                                                {respond instanceof PresuRespond
                                                    ? <ReceiptLongOutlined/>
                                                    : respond instanceof UserRespond
                                                        ? <MessageOutlined/>
                                                        : respond instanceof AdminRespond
                                                            ? <CommentOutlined/>
                                                            : null
                                                }
                                            </TimelineDot>
                                            <TimelineConnector/>
                                        </TimelineSeparator>
                                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                                            {respond.drawRespond(i18n.resolvedLanguage)}
                                        </TimelineContent>
                                    </TimelineItem>
                                })
                            }
                            {budgetData.getResponsesNum() === 0
                                ? null
                                : <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineConnector/>
                                        <TimelineDot color="primary" variant="outlined">
                                            <SendOutlined/>
                                        </TimelineDot>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                        <div style={{borderLeft:'1px solid grey', borderRadius:'5px', paddingLeft:'5px'}}>
                                            <div style={{fontSize:'22px'}}>Nueva respuesta</div>
                                            <div>Si lo ves necesario puedes añadir una respuesta en el hilo de este presupuesto.</div>
                                            <TextFieldMultiline title='Respuesta' id='respond'/>
                                            <div style={{display:'flex', marginRight:'7px'}}>
                                                <div style={{marginLeft:'auto'}}>
                                                    <Button variant="outlined" endIcon={<SendOutlined/>} disabled={loading} onClick={async () => {
                                                        setLoading(true);

                                                        const sendResponse = httpsCallable(functions, 'sendPresuRespond');

                                                        const respond = await sendResponse({
                                                            pid: budgetData.getId(),
                                                            text: document.getElementById('respond').value
                                                        });

                                                        await getBudgetData();

                                                        document.getElementById('respond').value = "";

                                                        setLoading(false);
                                                    }}>
                                                        {loading ? <CircularProgress/> : 'ENVIAR'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </TimelineContent>
                                </TimelineItem>
                            }
                        </Timeline>
                    </ThemeProvider>
                </div>
            </div>;
        }
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}