import { useNavigate } from "react-router-dom";
import AuthProvider from "../implementation/auth-provider";
import { useState } from "react";
import LoadingBarApp from "../implementation/loading";
import Navbar from "../implementation/navbar";
import { Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { CustomDividerVertical } from "../implementation/customUi";
import { ArrowBackIos, ChevronLeftOutlined, ChevronRightOutlined, SkipNextOutlined, SkipPreviousOutlined } from "@mui/icons-material";
import { theme } from "../style/theme";
import { httpsCallable, httpsCallableFromURL } from "firebase/functions";
import { functions } from "../firebase/firebase";
import { Payment } from "../classes/payment";

export default function Payments() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [payments, setPayments] = useState(null);
    const [totalPayments, setTotalPayments] = useState(null);
    const [loading, setLoading] = useState(false);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getPayments(data) {
        setLoading(true);
        console.log(data);

        const getPays = httpsCallable(functions, 'getPays');

        getPays(data).then(async result => {
            console.log(result.data);

            const payments = [];

            await Promise.all(result.data.payments.map(async payment => {
                payments.push(await Payment.fromFirestore(userr.uid, payment));
            }));

            console.log(payments);
            setPayments(payments);
            setTotalPayments(result.data.max);
            setLoading(false);
        });
    }

    function updatePagination(newData) {
        if (newData['perPage'] != perPage || newData['page'] != page) {
            setPerPage(newData['perPage']);
            setPage(newData['page']);

            getPayments(newData);
        }
    }

    if (state === 1) {
        if (payments == null && !loading) {
            getPayments({
                page: page,
                perPage: perPage
            });
            return <div></div>;
        }

        //style={{margin:'5px'}}

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div>
                <div style={{margin:'5px'}}><h1 className="page-title-p">
                    Pagos realizados
                </h1></div>
                <Divider/>
                {loading
                    ? <div style={{marginTop: '10px'}}>
                        <LoadingBarApp/>
                    </div>
                    : <div>
                        <div style={{display:'flex'}}>
                            <ThemeProvider theme={theme}>
                                <div style={{marginLeft:'auto', display:'flex', alignItems:'center'}}>
                                    <div style={{margin:'5px 0px'}}>Total: {totalPayments}</div>
                                    <CustomDividerVertical color="rgba(0, 0, 0, 0.12)" width="1px" extraMargin="5px" flex="66px"/>
                                    <IconButton disabled={page===1} onClick={() => {
                                        updatePagination({
                                            'perPage': perPage,
                                            'page': page-1
                                        });
                                    }}>
                                        <ChevronLeftOutlined/>
                                    </IconButton>
                                    <div>{page*perPage-perPage+1} - {page*perPage<=totalPayments?page*perPage:totalPayments}</div>
                                    <IconButton disabled={totalPayments/perPage<=1} onClick={() => {
                                        updatePagination({
                                            'perPage': perPage,
                                            'page': page+1
                                        });
                                    }}>
                                        <ChevronRightOutlined/>
                                    </IconButton>
                                    <CustomDividerVertical color="rgba(0, 0, 0, 0.12)" width="1px" extraMargin="5px" flex="66px"/>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id="perpage-selector-label">Ventas por página</InputLabel>
                                        <Select
                                            labelId="perpage-selector-label"
                                            id="perpage-selector"
                                            value={perPage}
                                            onChange={(event) => {
                                                updatePagination({
                                                    'perPage': event.target.value,
                                                    'page': 1
                                                });
                                            }}
                                            label="Ventas por página"
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </ThemeProvider>
                        </div>
                        <Divider/>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="payments-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nº de pago</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Resultado</TableCell>
                                        <TableCell>Valor total (€)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {payments.map((payment, index) => {
                                        return payment.drawTableRow(index, navigate);
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }
                <Divider/>
            </div>
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}>
            <LoadingBarApp/>
    </AuthProvider>
}