import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";

export class User {
    constructor (id, name, surname, secondSurname, birthday, phone, fiscalData) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.secondSurname = secondSurname;
        this.birthday = birthday;
        this.phone = phone;
        this.fiscalData = fiscalData;
    }

    static async fromFirestoreWithFiscalData (uid) {
        const userData = await getDoc(doc(db, 'users', uid));
        const fiscalData = await FiscalData.fromFirestore(uid);

        return new User(userData.id, userData.get('name'), userData.get('surname'), userData.get('secondSurname'), userData.get('birthday'), "" /* Guardar en base de datos el telefono de cliente */, fiscalData);
    }

    getName() {
        return this.name;
    }

    getSurname() {
        return this.surname;
    }

    getSecondSurname() {
        return this.secondSurname;
    }

    getStringBirthdate() {
        try {
            return `${this.birthday.toDate().getDate().toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })}/${(this.birthday.toDate().getMonth() + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false
                })}/${this.birthday.toDate().getFullYear()}`;
        } catch(e) {
            console.error(e);
            return '';
        }
    }

    getPhone() {
        return this.phone;
    }

    getFiscalData() {
        return this.fiscalData;
    }
}

export class FiscalData {
    constructor (name, address, id) {
        this.name = name;
        this.address = address;
        this.id = id;
    }

    static async fromFirestore(uid) {
        try {
            const fiscalData = await getDoc(doc(db, 'users', uid, 'billing', 'data'));

            if (fiscalData.data() === undefined) {
                return null;
            }

            return new FiscalData(fiscalData.get('name'), fiscalData.get('address'), fiscalData.get('id'));
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    getName() {
        return this.name;
    }

    getAddress() {
        return this.address;
    }

    getId() {
        return this.id;
    }
}