import { Link, useNavigate } from 'react-router-dom'
import '../style/navbar.css'
import logo from '../assets/img/LogoTextBM.png';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Badge, Button, Collapse, Divider, Drawer, FormControl, InputLabel, List, ListItem, Select, ThemeProvider, Tooltip, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { auth, db, functions } from '../firebase/firebase';
import { LoginOutlined, PaymentsOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import LoadingBarApp from './loading';
import { CustomDividerHorizontal, CustomDividerVertical, RoundButton } from './customUi';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import { resaltTheme, secondaryTheme } from '../style/theme';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';

export default function Navbar({ drawer=false, toggleDrawer, drawerIcon, badgeCount, drawerIconTooltip, userId, handleOnCart=(cart) => {console.log(cart)} }) {
    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [carrito, setCarrito] = useState(null);
    const [carritoObj, setCarritoObj] = useState(null);
    const [cartOpen, setCartOpen] = useState(false);
    const [fakeState, setFakeState] = useState(false);

    const [optionsOpened, setOptionsOpened] = useState(false);

    const theme = createTheme({
      palette: {
        primary: {
          main: '#36454f'
        }
      }
    });

    const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function getCart() {
      const userDataRef = doc(db, 'users', userId);
      const userData = await getDoc(userDataRef);

      if (userData.get('carrito')) {
        setCarrito(userData.get('carrito'));
        handleOnCart(userData.get('carrito'));
      } else {
        setCarrito([]);
        handleOnCart([]);
      }

      const updateCart = onSnapshot(userDataRef, (docData) => {
        if (docData.get('carrito')) {
          setCarritoObj(null);
          setCarrito(docData.get('carrito'));
          handleOnCart(docData.get('carrito'));
        } else {
          setCarritoObj(null);
          setCarrito([]);
          handleOnCart([]);
        }
      });
    }

    async function getCartData() {
      console.log("Carrito:");
      console.log(carrito);
      const newCarrito = {};
      
      /*

      await Promise.all(carrito.map(async cartObj => {
        const docData = await getDoc(doc(db, 'courses', cartObj));
        const courseData = Course.fromDataBase(docData);

        const priceRef = await getDocs(query(collection(db, 'products', courseData.getId(), 'prices'), where('active', '==', true)));
                courseData.updatePrice(priceRef.docs[0].get('unit_amount'));
                if (priceRef.docs[1])
                    courseData.updateSecondPrice(priceRef.docs[1].get('unit_amount'));

        newCarrito[cartObj] = courseData;
      }));

      setCarritoObj(newCarrito);

      */
    }

    function calcTotalPrice() {
      var totalPrice = 0;

      if (carritoObj !== null) {
        carrito.map(cartObj => {
          if (carritoObj[cartObj].getSecondPrice()) {
            totalPrice += carritoObj[cartObj].getSecondPrice();
          } else {
            totalPrice += carritoObj[cartObj].getPrice();
          }
        });
      }

      return totalPrice;
    }

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
          window.removeEventListener("resize", handleResize);
      };
    }, []);

    const isLaptop = useMediaQuery({
      query: '(min-width: 640px)'
  });

  const selectLanguage = <ThemeProvider theme={theme}>
    <div style={{flex:isLaptop?'':'1', display:'flex', justifyContent:'center'}}>
      <FormControl sx={{ m: 1, minWidth: 120}} size="small">
        <InputLabel id="language-select-lable">Idioma</InputLabel>
        <Select
          MenuProps={{
            sx: {
              "&& .MuiMenu-list": {
                backgroundColor: resaltTheme.palette.secondary.main
              }
            }
          }}
          labelId="language-select-lable"
          id="language-select"
          value={i18n.resolvedLanguage}
          label="Idioma"
          onChange={(event) => {
            i18n.changeLanguage(event.target.value);
          }}
          sx={{color: theme.palette.primary.main}}
        >
          <MenuItem value="es">Español</MenuItem>
          <MenuItem value="ca">Català</MenuItem>
        </Select>
      </FormControl>
    </div>
  </ThemeProvider>;

  const userProv = userId === null
    ? <ThemeProvider theme={theme} style={{flex:isLaptop?'':'1'}}>
      <RoundButton text={"Iniciar sesión"} endIcon={<LoginOutlined/>} onClick={async () => {
        navigate('/login');
        return false;
      }}/>
    </ThemeProvider>
    : <div style={{flex:isLaptop?'':'1', display:'flex', justifyContent:'center'}}>
      <IconButton
        size='large'
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        style={{color:"#36454f", padding:3,}}
        className='button'
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "&& .MuiMenu-list": {
            backgroundColor: resaltTheme.palette.secondary.main
          }
        }}
      >
        <MenuItem onClick={() => {navigate("/dashboard")}}>Panel de control</MenuItem>
        <MenuItem onClick={handleClose}>Contacto</MenuItem>
        <MenuItem onClick={() => {navigate("/logout");}}>Cerrar sesión</MenuItem>
      </Menu>
    </div>;
  
  const carritoView = carrito!==null && carrito.length !==0
    ? <ThemeProvider theme={createTheme({
      palette: {
          primary: {
              main: "#36454f"
          }
      }
    })}>
      <div style={{flex:isLaptop?'':'1', display:'flex', justifyContent:'center'}}>
        <Badge badgeContent={carrito.length} max={9} color="primary" style={{marginRight:'15px'}}>
          <IconButton onClick={() => {setCartOpen(true)}}>
            <ShoppingCartOutlined color='primary'/>
          </IconButton>
        </Badge>
      </div>
    </ThemeProvider>
    : null;

  const routesView = <ThemeProvider theme={theme}>
    <div style={{display:isLaptop?'flex':''}}>
      <Button variant='text' onClick={() => {navigate("/")}}>INICIO</Button>
      <Button variant='text' onClick={() => {navigate("/#bitmasters-section")}}>SOBRE NOSOTROS</Button>
    </div>
  </ThemeProvider>;

  if ((carrito !== null && carritoObj === null) && userId != null) {
    getCartData();
  }

  if (carrito === null && userId != null)
    getCart();

  return <div>
    <ul className="navbar">
        {drawer? <Tooltip title={drawerIconTooltip}><Button style={{margin:2, padding:2, minWidth:0}} onClick={toggleDrawer(true)}><ThemeProvider theme={theme}><Badge badgeContent={badgeCount} color='primary'>{drawerIcon}</Badge></ThemeProvider></Button></Tooltip> : <div></div>}
        <div><Link className='navbar' to="/"><div className='button' id='no-hover'>
            <img className='logo' src={logo} style={{maxWidth:!isLaptop?`${windowWidth-55}px`:''}} alt='Logo'/>
        </div></Link></div>
        {isLaptop
          ? <div id='right'>
            {routesView}
            {selectLanguage}
            <div style={{width:'10px'}}/>
            {userProv}
            {carritoView}
          </div>
          : <div id='right'>
            <ThemeProvider theme={theme}>
              <IconButton onClick={() => {
                setOptionsOpened(!optionsOpened)
              }}>
                <MenuIcon/>
              </IconButton>
            </ThemeProvider>
          </div>
        }
        <Drawer
          anchor="right"
          open={cartOpen}
          onClose={() => {setCartOpen(false)}}
        >
          {carrito !== null
            ? <List className='cart-drawer'>
              {carrito.map((cartObj, index) => {
                return <ListItem key={index}>
                  {carritoObj === null || (carritoObj!==null && carritoObj[cartObj] === null)
                    ? <LoadingBarApp/>
                    : <div>{carritoObj[cartObj].drawCartCard(() => {
                      console.log(carrito.splice(carrito.indexOf(cartObj, 1)));
                      const newCarrito = carrito.splice(carrito.indexOf(cartObj), 1);
                      updateDoc(doc(db, 'users', userId), {
                        "carrito": newCarrito
                      });
                    })}</div>
                  }
                </ListItem>
              })}
              <div style={{height:'5px'}}/>
              <div style={{display:'flex', margin: '5px 15px', fontSize:'24px', fontWeight:'700', fontFamily:'Ubuntu'}}>
                <div>
                  Total:
                </div>
                <div style={{marginLeft:'auto'}}>
                  {(calcTotalPrice()/100).toFixed(2)}€
                </div>
              </div>
              <div style={{margin:"0px 10px", marginTop:'auto'}}>
                <ThemeProvider theme={createTheme({
                  palette: {
                      primary: {
                          main: "#36454f"
                      }
                  }
                })}>
                  <RoundButton text="Revisar y pagar" endIcon={<PaymentsOutlined/>} onClick={() => {
                    const payCart = httpsCallable(functions, 'payCart');
                    payCart().then((result) => {
                      console.log(result.data);

                      const waitPaymentUrl = onSnapshot(doc(db, 'customers', userId, 'checkout_sessions', result.data), (doc) => {
                        if (doc.get('url')) {
                            console.log(doc.get('url'));
                            window.location.href = doc.get('url');
                        }
                      });
                    });
                  }}/>
                </ThemeProvider>
              </div>
            </List>
            : <div className='cart-drawer'/>
          }
        </Drawer>
    </ul>
    <Collapse
      in={optionsOpened&&!isLaptop}
      sx={{position:'fixed', top:'64px', zIndex:60, width:'-webkit-fill-available', '&& .MuiCollapse-root': {
        backgroundColor: '#708090'
      }}}
    >
      <div style={{backgroundColor:'#708090'}}>
        <CustomDividerHorizontal color={resaltTheme.palette.primary.main} height="2px"/>
        <div style={{height:'5px'}}/>
        <div style={{display:'flex', alignItems: 'center', columnWidth: 'calc(100% / 2)'}}>
          {selectLanguage}
          <CustomDividerVertical color={resaltTheme.palette.primary.main} width="2px" flex="50px"/>
          {userProv}
          {carrito != null && carrito.length > 0
            ? <CustomDividerVertical color={resaltTheme.palette.primary.main} width="2px" flex="50px"/>
            : null
          }
          {carritoView}
        </div>
        <div style={{height:'5px'}}/>
        <CustomDividerHorizontal color={resaltTheme.palette.primary.main} height="2px"/>
      </div>
    </Collapse>
  </div>
}