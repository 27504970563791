import { Divider } from '@mui/material';
import '../style/dashboard.css';

export default function PageBoxButton({ children, onClick, title, description, icon }) {
    return <div className="page-button" onClick={onClick}>
        <div style={{margin:'5px'}}>
            <div style={{display:'flex', alignItems: "center"}}>
                {icon}
                <div style={{width:'5px'}}/>
                <div className="button-box-title">
                    {title}
                </div>
            </div>
            <Divider style={{borderColor:'white'}}/>
            <div className="button-box-description">
                {description}
            </div>
        </div>
    </div>
}

export function PageBoxButton1({ children, onClick }) {
    return <div className="page-button" onClick={onClick}>
        <div style={{margin:'5px'}}>
            {children}
        </div>
    </div>
}