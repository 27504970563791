import { Alert, Button, CircularProgress, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton, ThemeProvider } from "@mui/material";
import AuthProvider from "../implementation/auth-provider";
import LoadingBarApp from "../implementation/loading";
import { AlternateEmailOutlined, EditOutlined, HelpOutline, Password, Search } from "@mui/icons-material";
import Navbar from "../implementation/navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { User } from "../classes/user";
import { CustomDividerHorizontal, DisplayTextInfo, RoundButton } from "../implementation/customUi";
import '../style/style.css';
import { secondaryTheme, theme } from "../style/theme";
import { CustomTextField, TextField } from "../implementation/textFielf";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

export default function Account() {
    const { t } = useTranslation();

    const navigate = useNavigate();
    /*
      State
      0: Loading
      1: Logged in, loading data
      3: Logged in, data loaded
    */
    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);
    const [userData, setUserData] = useState(null);

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [customInfo, setCustomInfo] = useState(false);
    const [fiscalEditInfo, setFiscalEditInfo] = useState(false);

    const [dataMsg, setDataMsg] = useState(null);
    const [msg, setMsg] = useState(null);

    const [notification, setNotification] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getData() {
        const user = await User.fromFirestoreWithFiscalData(userr.uid);

        setUserData(user);
        setCurrentState(2);
        console.log(user);
    }

    if (state === 2) {
        return <div>
            <Navbar userId={userr.uid}/>
            {notification === null
                ? <div></div>
                : <div className="notification">
                    <Alert severity="success" sx={{border:'2px solid green', borderRadius:'6px', alignItems:'center'}} onClose={() => {setNotification(null);}}>{notification}</Alert>
                </div>
            }
            <div style={{height:'64px'}}/>
            <div style={{margin:'15px'}}>
                {msg
                    ? <Alert severity="success" sx={{border:'1px solid green'}}>{msg}</Alert>
                    : <div></div>
                }
                <h1 style={{margin: 0, fontSize:'36px'}}>Información de usuario</h1>
                <div style={{marginLeft:'30px'}}>
                    <CustomDividerHorizontal color="grey" height="1px" extraMargin="10px"/>
                    <DisplayTextInfo title="E-Mail" text={userr.email}/>
                    <div style={{height:'20px'}}/>
                    <DisplayTextInfo title="Nombre Completo" text={`${userData.getName()} ${userData.getSurname()} ${userData.getSecondSurname()}`}/>
                    <div style={{height:'20px'}}/>
                    <DisplayTextInfo title="Fecha de nacimiento" text={userData.getStringBirthdate()}/>
                    <div style={{height:'50px'}}/>
                </div>
                <div style={{display:'flex'}}>
                    <h1 style={{margin: 0, fontSize:'36px'}}>Información de facturación</h1>
                    {userData.getFiscalData() != null
                        ? <ThemeProvider theme={createTheme({
                            palette: {
                                primary: {
                                    main: "#000"
                                }
                            }
                        })}>
                            <IconButton style={{marginLeft:'5px'}} onClick={() => {
                                console.log("Edit");
                                setFiscalEditInfo(true);
                            }}>
                                <EditOutlined/>
                            </IconButton>
                            <Dialog
                                open={fiscalEditInfo}
                                onClose={() => {setFiscalEditInfo(false)}}
                                sx={{zIndex:'20'}}
                            >
                                <DialogTitle>Cambiar los datos de facturación</DialogTitle>
                                <DialogContent style={{textAlign:'justify'}}>
                                    <div>Para poder cambiar los datos de facturación deve ponerse en contacto con nosotros a través de la direacción de correo electrónico <span><a className="text-link" href="mailto:info@bitmasters.es?subject=Cambiar%20datos%20de%20facturación">info@bitmasters.es</a></span> y darnos sus nuevos datos para que podamos hacer el cambio.</div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => {setFiscalEditInfo(false)}} autoFocus>OK</Button>
                                </DialogActions>
                            </Dialog>
                        </ThemeProvider>
                        : <div></div>
                    }
                </div>
                <div style={{marginLeft:'30px'}}>
                    <CustomDividerHorizontal color="grey" height="1px" extraMargin="10px"/>
                    {userData.getFiscalData() != null
                        ? <div>
                            <DisplayTextInfo title="Nombre Fiscal" text={userData.getFiscalData().getName()}/>
                            <div style={{height:'20px'}}/>
                            <DisplayTextInfo title="ID Fiscal" text={userData.getFiscalData().getId()}/>
                            <div style={{height:'20px'}}/>
                            <DisplayTextInfo title="Dirección Fiscal" text={userData.getFiscalData().getAddress()}/>
                            <div style={{height:'50px'}}/>
                        </div>
                        : <div style={{fontSize: '22px', fontWeight:'700'}}>
                            Aún no se ha configurado la información fiscal. <span className="text-button" onClick={() => {setOpen(true)}}>Configurar ahora</span>
                            <ThemeProvider theme={theme}>
                                <Dialog
                                    open={open}
                                    onClose={() => {setOpen(false)}}
                                    sx={{zIndex:'1'}}
                                >
                                    <DialogTitle>Configurar la información fiscal</DialogTitle>
                                    <DialogContent style={{width:'600px', maxWidth:'-webkit-fill-available'}}>
                                        {dataMsg
                                            ? <Alert severity="error" sx={{border:'1px solid red'}}>{dataMsg}</Alert>
                                            : <div></div>
                                        }
                                        <TextField id="name-field" title="Nombre fiscal"/>
                                        <TextField id="id-field" title="ID fiscal (DNI, CIF, etc.)"/>
                                        <CustomTextField id="address-field" title="Dirección fiscal" icon={<HelpOutline/>} onClick={() => {
                                            setCustomInfo(true);
                                        }}/>
                                        <Dialog
                                            open={customInfo}
                                            onClose={() => {setCustomInfo(false)}}
                                            sx={{zIndex:'20'}}
                                        >
                                            <DialogTitle>Configurar la información fiscal</DialogTitle>
                                            <DialogContent style={{textAlign:'justify'}}>
                                                Una dirección de ejemplo sería: Calle Ejemplo 4, 1º 1ª, 08000 Ciudad, Provincia, País
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => {setCustomInfo(false)}} autoFocus>OK</Button>
                                            </DialogActions>
                                        </Dialog>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => {setOpen(false)}} disabled={loading}>Cancelar</Button>
                                        <Button onClick={async () => {
                                            setLoading(true);

                                            const data = {
                                                name: document.getElementById('name-field').value,
                                                id: document.getElementById('id-field').value,
                                                address: document.getElementById('address-field').value
                                            };

                                            console.log(data);

                                            if (data.name != "" && data.id != "" && data.address != "") {
                                                try {
                                                    await setDoc(doc(db, 'users', userr.uid, 'billing', 'data'), data);
                                                    setMsg("Se ha actualizado la información fiscal.");
                                                    setDataMsg(null);
                                                    setOpen(false);
                                                    setCurrentState(1);
                                                } catch (e) {
                                                    console.error(e);
                                                    setDataMsg("Algo ha ido mal, vuelve a intentarlo o pongase en contacto con nosotros.");
                                                }
                                            } else {
                                                setDataMsg("Deve rellenar todos los campos");
                                            }

                                            setLoading(false);
                                        }} autoFocus disabled={loading}>{loading?<CircularProgress/>:"Aceptar"}</Button>
                                    </DialogActions>
                                </Dialog>
                            </ThemeProvider>
                        </div>
                    }
                </div>
                <CustomDividerHorizontal color="grey" height="2px" extraMargin="20px"/>
                <ThemeProvider theme={theme}>
                    <RoundButton text="Cambiar contraseña" endIcon={<Password/>} onClick={async () => {
                        await sendPasswordResetEmail(auth, userr.email);
                        setNotification(`Se ha enviado un correo electrónico a ${userr.email} con el enlace para hacer el cambio de contraseña.`);
                        return false;
                    }}/>
                    <div style={{height:'10px'}}/>
                    <RoundButton text="Cambiar dirección de correo electrónico" endIcon={<AlternateEmailOutlined/>} onClick={async () => {
                        //await sendEmail
                        return false;
                    }}/>
                </ThemeProvider>
            </div>
        </div>;
    }

    if (state === 1) {
        getData();

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <LoadingBarApp/>
        </div>;
    }
    
    return <AuthProvider
      onUserLoggedIn={handleOnUserLoggedIn}
      onUserNotRegistered={handleUserNotRegistered}
      onUserNotLoggedIn={handleUserNotLoggedIn}>
        <LoadingBarApp/>
    </AuthProvider>;
}