import { Divider, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PageBoxButton from "../components/page-box";
import { useNavigate } from "react-router-dom";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SourceIcon from '@mui/icons-material/Source';
import AuthProvider from "../implementation/auth-provider";
import { useState } from "react";
import LoadingBarApp from "../implementation/loading";
import { ReceiptLongOutlined, ReceiptOutlined, SchoolOutlined, Settings } from "@mui/icons-material";
import Navbar from "../implementation/navbar";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { Presupuesto } from "../classes/presupuesto";
import { Table } from "react-bootstrap";

export default function Budgets() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [budgets, setBudgets] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getButgets() {
        const bufgetsData = await getDocs(collection(db, 'users', userr.uid, 'presupuestos'));

        const budgets = [];

        await Promise.all(bufgetsData.docs.map(async budgetData => {
            budgets[Number(budgetData.id)] = await Presupuesto.fromDataBaseNoFields(budgetData);
        }));

        console.log(budgets);
        setBudgets(budgets);
    }

    if (state === 1) {
        if (budgets === null) {
            getButgets();

            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 className="page-title-p">
                        Presupuestos
                    </h1>
                    <Divider/>
                    <LoadingBarApp/>
                </div>
            </div>;
        } else {
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div style={{margin:'5px'}}>
                    <h1 className="page-title-p">
                        Presupuestos
                    </h1>
                    <Divider/>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="payments-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nº de presupuesto</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Respuestas</TableCell>
                                    <TableCell>Valor total (€)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {budgets.map((budget, index) => {
                                    return budget.drawTableRow(index, navigate);
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>;
        }
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}