import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import LoadingBarApp from "../implementation/loading";
import { Alert, ThemeProvider, useScrollTrigger } from "@mui/material";
import { useState } from "react";
import { TextFieldPassword } from "../implementation/textFielf";
import { theme } from "../style/theme";
import { RoundButton } from "../implementation/customUi";
import CheckIcon from '@mui/icons-material/Check';
import { brown } from "@mui/material/colors";

export default function HandleResetPassword({ auth, actionCode }) {
    const [state, setCurrentState] = useState(0);
    /*
        0: Initializing
        1: Error: Expired link
        2: All OK by the moment
        3: Some undefined error
        4: PAssword changed
    */

    const [accountEmail, setAccountEmail] = useState(null);
    const [msg, setMsg] = useState(null);

    if (state === 0) {
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            setAccountEmail(email);
            setCurrentState(2);
            console.log(email);
        }).catch(e => {
            console.error(e);
            setCurrentState(1);
        });
    }

    if (state === 4) {
        return <div>
            La contraseña se ha cambiado de manera exitosa. Ya puede cerrar esta ventana y volver a iniciar sesión.
        </div>;
    }

    if (state === 2) {
        return <div>
            {msg != null
                ? <div>
                    <Alert severity="error" sx={{border:'1px solid red'}}>{msg}</Alert>
                    <div style={{height:'10px'}}/>
                </div>
                : <div></div>
            }
            <div>Especifica la nueva contraseña para la cuenta de <span style={{fontWeight:'600'}}>{accountEmail}</span>:</div>
            <div style={{height:'10px'}}/>
            <TextFieldPassword title="Nueva contraseña" id="new-password-field"/>
            <ThemeProvider theme={theme}>
                <RoundButton text="Guardar" endIcon={<CheckIcon/>} onClick={async () => {
                    const newPassword = document.getElementById('new-password-field').value;
                    console.log(newPassword);

                    try {
                        await confirmPasswordReset(auth, actionCode, newPassword);
                        setCurrentState(4);
                    } catch (e) {
                        console.error(e.code);
                        
                        switch (e.code) {
                            case "auth/weak-password":
                                setMsg("La nueva contraseña no es segura, debe tener como mínimo 6 caracteres y debe contener letras en minúsculas y mayúsculas, números y símbolos (-_.#@).");
                                break;
                            default:
                                setCurrentState(3);
                                break;
                        }
                    }
                    
                    return false;
                }}/>
            </ThemeProvider>
        </div>;
    }

    if (state === 3) {
        return <div>
            <Alert severity="error" sx={{border:'1px solid red'}}>Ha ocurrido un erro desconocido. Vuelva a intentarlo más tarde.</Alert>
        </div>;
    }
    
    if (state === 1) {
        return <div>
            <Alert severity="error" sx={{border:'1px solid red'}}>Parece que este enlace ya ha expirado o se ha usado.</Alert>
        </div>;
    }

    return <div>
        <LoadingBarApp/>
    </div>;
}