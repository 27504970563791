import { useState } from "react";
import { CustomTextField, TextField } from "../implementation/textFielf";
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { CloseOutlined, InfoOutlined } from "@mui/icons-material";
import { Collapse } from "react-bootstrap";
import { Divider, FormControlLabel, IconButton, Switch } from "@mui/material";
import { CustomDividerHorizontal } from "../implementation/customUi";

export class CalculatorField {
    constructor (id, title, type, defaultVal, info, price) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.defaultVal = defaultVal;
        this.info = info;
        this.price = price;
        this.value = defaultVal;
    }

    static async fromDataBase(docData) {
        const retVal = [];

        docData.map((doc) => {
            console.log(doc);
            const field = new CalculatorField(doc.id, doc.title, doc.type, doc.default, doc.info, doc.price);
            retVal.push(field);
        });

        return retVal;
    }

    getId() {
        return this.id;
    }

    getType() {
        return this.type;
    }

    getTitle() {
        return this.title;
    }

    getDefault() {
        return this.defaultVal;
    }

    getInfo() {
        return this.info;
    }

    getPrice() {
        return this.price;
    }

    getValue() {
        return this.value;
    }

    updateValue(value) {
        this.value = value;
    }
}

export function DrawField({field, onChange = () => {}}) {
    const {t, i18n} = useTranslation();
        
    const [showInfo, setShowInfo] = useState(false);

    if (field.getType() === 'int') {
        return <div style={{width: '-webkit-fill-available'}}>
            <div style={{width: '-webkit-fill-available'}}>
                <CustomTextField title={field.getTitle()[i18n.resolvedLanguage]} id={field.getId()} icon={showInfo? <CloseOutlined/> : <InfoOutlined/>} onClick={() => {setShowInfo(!showInfo)}} defaultVal={field.getDefault()} type="number" onChange={(event) => {
                    field.updateValue(Number(event.target.value));
                    onChange();
                }}/>
            </div>
            <div style={{marginLeft:'20px'}}>
                <Collapse in={showInfo} timeout={500} unmountOnExit>
                    <Markup content={field.getInfo()[i18n.resolvedLanguage]}/>
                </Collapse>
            </div>
            <div style={{height:'20px'}}/>
        </div>;
    } else if (field.getType() === 'bool') {
        return <div style={{width: '-webkit-fill-available', margin:'0px 13px'}}>
            <div style={{width: '-webkit-fill-available', display:'flex', alignItems:'center'}}>
                <FormControlLabel id={field.getId()} control={<Switch defaultChecked={field.getDefault()} />} label={field.getTitle()[i18n.resolvedLanguage]} labelPlacement="start" style={{marginLeft:'0px'}} onChange={(event) => {
                    field.updateValue(event.target.checked);
                    onChange();
                }}/>
                <IconButton onClick={() => {setShowInfo(!showInfo)}} style={{marginLeft:'10px'}}>
                    {showInfo? <CloseOutlined/> : <InfoOutlined/>}
                </IconButton>
            </div>
            <div style={{marginLeft:'20px'}}>
                <Collapse in={showInfo} timeout={500} unmountOnExit>
                    <Markup content={field.getInfo()[i18n.resolvedLanguage]}/>
                </Collapse>
            </div>
            <div style={{height:'20px'}}/>
        </div>;
    } else if (field.getType() === 'div') {
        return <CustomDividerHorizontal color="grey" height="1px" extraMargin="5px"/>;
    }
}