import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, ThemeProvider } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { theme } from "../style/theme";
import { useState } from "react";

export function TextFieldPassword({ title, id }) {
    const [showPassword, setShowPasword] = useState(false);

    return <ThemeProvider theme={theme}>
        <FormControl sx={{ m: 1, width: '-webkit-fill-available' }} variant="outlined">
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <OutlinedInput
                id={id}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                                setShowPasword(!showPassword);
                            }}
                            onMouseDown={(event) => {
                                event.preventDefault();
                            }}
                            onMouseUp={(event) => {
                                event.preventDefault();
                            }}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label={title}
            />
        </FormControl>
    </ThemeProvider>;
}

export function TextField({ title, id }) {
    return <ThemeProvider theme={theme}>
        <FormControl sx={{ m: 1, width: '-webkit-fill-available' }} variant="outlined">
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <OutlinedInput
                id={id}
                label={title}
            />
        </FormControl>
    </ThemeProvider>;
}

export function CustomTextField({ title, id, icon, onClick, defaultVal="", type="text", onChange=(event)=>{} }) {
    return <ThemeProvider theme={theme}>
        <FormControl sx={{ m: 1, width: '-webkit-fill-available' }} variant="outlined">
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <OutlinedInput
                id={id}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={onClick}
                            onMouseDown={(event) => {
                                event.preventDefault();
                            }}
                            onMouseUp={(event) => {
                                event.preventDefault();
                            }}
                            edge="end"
                        >
                            {icon}
                        </IconButton>
                    </InputAdornment>
                }
                label={title}
                defaultValue={defaultVal}
                type={type}
            />
        </FormControl>
    </ThemeProvider>;
}

export function TextFieldMultiline({ title='', id='', maxRows=4, minRows=4 }) {
    return <ThemeProvider theme={theme}>
        <FormControl sx={{ m: 1, width: '-webkit-fill-available' }} variant="outlined">
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <OutlinedInput
                id={id}
                label={title}
                multiline
                minRows={minRows}
                maxRows={maxRows}
            />
        </FormControl>
    </ThemeProvider>;
}