import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthPage from './routes/auth';
import NotFoundPage from './routes/notFound';
import Account from './routes/account';
import LoginView from './routes/login';
import Logout from './routes/logout';
import Dashboard from './routes/dashboard';
import Payments from './routes/payments';
import PaymentView from './routes/paymentView';
import FacturaView from './routes/facturaView';
import HomePage from './routes/homePage';
import 'bootstrap/dist/css/bootstrap.min.css';

import './i18n';
import CalculatorPage from './routes/calculator';
import Budgets from './routes/butgets';
import BudgetView from './routes/budget';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='login' element={<LoginView/>}/>
      <Route path='logout' element={<Logout/>}/>
      <Route path="auth/action" element={<AuthPage/>}/>
      <Route path='dashboard' element={<Dashboard/>}/>
      <Route path='dashboard/account' element={<Account/>}/>
      <Route path='dashboard/payments' element={<Payments/>}/>
      <Route path='dashboard/payments/:pid' element={<PaymentView/>}/>
      <Route path='dashboard/payments/:pid/factura' element={<FacturaView/>}/>
      <Route path='dashboard/budgets' element={<Budgets/>}/>
      <Route path='dashboard/budgets/:bid' element={<BudgetView/>}/>
      <Route path='calculator' element={<CalculatorPage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
