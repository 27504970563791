import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userExists, auth, userIsTeacher, publicUser } from "../firebase/firebase";

export default function AuthProvider({ children, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered }) {
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const isRegistered = await userExists(user.uid);
                if (isRegistered) {
                    onUserLoggedIn(user);
                } else {
                    onUserNotRegistered(user);
                }
            } else {
                onUserNotLoggedIn();
            }
        })
    }, [navigate, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered]);
    return <div>{children}</div>;
}

export function AuthProviderTeacher({ children, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered, onUserNotTeacher }) {
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const isRegistered = await userExists(user.uid);
                if (isRegistered) {
                    const isTeacher = await userIsTeacher(user.uid);

                    if (isTeacher) {
                        onUserLoggedIn(user);
                    } else {
                        onUserNotTeacher();
                    }
                } else {
                    onUserNotRegistered(user);
                }
            } else {
                onUserNotLoggedIn();
            }
        })
    }, [navigate, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered, onUserNotTeacher]);
    return <div>{children}</div>;
}

export function AuthProviderForum({ children, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered, onUserNotPublicProfile }) {
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const isRegistered = await userExists(user.uid);
                if (isRegistered) {
                    const isPublicUser = await publicUser(user.uid);

                    if (isPublicUser) {
                        onUserLoggedIn(user);
                    } else {
                        onUserNotPublicProfile(user);
                    }
                } else {
                    onUserNotRegistered(user);
                }
            } else {
                onUserNotLoggedIn();
            }
        })
    }, [navigate, onUserLoggedIn, onUserNotLoggedIn, onUserNotRegistered]);
    return <div>{children}</div>;
}