import { useNavigate } from 'react-router-dom';
import Navbar from '../implementation/navbar';
import { useState } from 'react';
import AuthProvider from '../implementation/auth-provider';
import LoadingBarApp from '../implementation/loading';

import { Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Icon, IconButton, ThemeProvider } from '@mui/material';
import { CustomDividerHorizontal, RoundButton } from '../implementation/customUi';

import '../style/carrousel.css';
import { useMediaQuery } from 'react-responsive';

import { CalculateOutlined, CloseOutlined, LoginOutlined, OpenInNewOutlined, SendOutlined } from '@mui/icons-material';
import { resaltTheme, theme } from '../style/theme';

import { useTranslation } from "react-i18next";
import { doc, getDoc } from 'firebase/firestore';
import { db, functions } from '../firebase/firebase';
import { CalculatorField, DrawField } from '../classes/calculator';
import { TextField, TextFieldMultiline } from '../implementation/textFielf';
import { httpsCallable } from 'firebase/functions';

export default function CalculatorPage() {
    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [fields, setFields] = useState(null);

    const [price, setPrice] = useState(null);
    const [priceSetted, setPriceSetted] = useState(false);

    const [sendPresu, setSendPresu] = useState(false);

    const [msg, setMsg] = useState(null);

    const isLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 530px)'
    });

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        setCurrentState(1);
    }

    async function getCalculatorData() {
        const calculatorField = await getDoc(doc(db, 'academyWebInfo', 'calculator'));
        const fieldsNew = await CalculatorField.fromDataBase(calculatorField.get('fields'));

        console.log(fieldsNew);
        setFields(fieldsNew);
        setCurrentState(2);
    }

    if (state === 1) {
        getCalculatorData();

        return <div>
            <Navbar userId={userr != null? userr.uid : null}/>
            <div style={{height:'64px', backgroundColor:'#708090'}}/>
            <LoadingBarApp/>
        </div>;
    }

    if (state === 2) {
        if (fields === null) {
            return <div>
                <Navbar userId={userr != null? userr.uid : null}/>
                <div style={{height:'64px', backgroundColor:'#708090'}}/>
                <div style={{margin:'10px'}}>
                    <Alert severity="error">Algo ha ido mal al cargar los datos para la calculadora. Por favor, vuelve a intentarlo.</Alert>
                </div>
            </div>;
        } else {
            return <div>
            <Navbar userId={userr != null? userr.uid : null}/>
            <div style={{height:'64px', backgroundColor:'#708090'}}/>
            {msg != null
                ? <div className='notification' style={{'--bordercolor': msg.type === 'success' ? '#4caf50' : '#f44336'}}>
                    <Alert severity={msg.type} sx={{alignItems:'center'}}><div style={{display:'flex', alignItems:'center'}}>
                        {msg.message}
                        <IconButton onClick={() => {
                            setMsg(null);
                        }}>
                            <CloseOutlined/>
                        </IconButton>
                    </div></Alert>
                </div>
                : null
            }
            <div style={{margin:'10px', width:'-webkit-fill-available'}}>
                <h1 className="page-title-p">
                    Calculadora de precios
                </h1>
                <Divider/>
                <div style={{margin:'15px 0px'}}>
                    Está calculadora es útil para poder obtener un precio aproximado y general para un presupuesto de una página web, aplicación, etc. Para poder obtener un presupuesto real puede solicitar uno a través del enlace que encontrará más abajo.
                </div>
                <div style={{height:'20px'}}/>
                <ThemeProvider theme={theme}>
                    {fields.map((field, index) => {
                        return <DrawField key={index} field={field} index={index} onChange={() => {
                            setPriceSetted(false);
                        }}/>;
                    })}
                </ThemeProvider>
                <CustomDividerHorizontal color="grey" height="1px" extraMargin="5px"/>
                <div style={{margin:'20px'}}>
                    {price != null
                        ? <div style={{margin:'30px', textAlign:'center', fontSize:'40px', fontFamily:'Ubuntu', fontWeight:'700'}}>
                            {price === '...'
                                ? <ThemeProvider theme={theme}><CircularProgress/></ThemeProvider>
                                : `${price} €`
                            }
                        </div>
                        : null
                    }
                    <ThemeProvider theme={resaltTheme}>
                        <div style={{display:'flex'}}>
                            <RoundButton text={"Calcular precio"} onClick={async () => {
                                setPrice('...');

                                await new Promise(r => setTimeout(r, 1000));
                                
                                var price = 0;

                                fields.map((field, index) => {
                                    if (field.getType() === 'int') {
                                        price += field.getPrice() * field.getValue();
                                    } else if (field.getType() === 'bool') {
                                        if (field.getValue()) {
                                            price += field.getPrice();
                                        }
                                    }
                                });

                                console.log(price);

                                setPrice(price);
                                setPriceSetted(true);

                                return true;
                            }} endIcon={<CalculateOutlined/>} disabled={priceSetted}/>
                            <div style={{width:'20px'}}/>
                            <RoundButton text={"Solicitar un presupuesto"} onClick={async () => {
                                setSendPresu(true);
                                return true;
                            }} endIcon={<OpenInNewOutlined/>}/>
                        </div>
                    </ThemeProvider>
                </div>
                <Dialog
                    open={sendPresu}
                    onClose={() => {
                        setSendPresu(false);
                    }}
                >
                    <DialogTitle>Solicitud de presupuesto</DialogTitle>
                    {userr != null
                        ? <DialogContent>
                            <div style={{margin:'20px'}}>
                                <div className='title middle'>Revisión del presupuesto</div>
                                <ThemeProvider theme={theme}>
                                    {fields.map((field, index) => {
                                        return <DrawField key={index} field={field} index={index} onChange={() => {
                                            setPriceSetted(false);
                                        }}/>;
                                    })}
                                    <CustomDividerHorizontal color="grey" height="1px" extraMargin="20px"/>
                                    <div className='title middle'>Observaciones</div>
                                    <div>Para cualquier observación sobre el funcionamiento, el modelo de negocio, etc. puedes rellenar este campo. Además, sería interesante que nos pudierás dar ejemplos de páginas web en las que te gustaría inspirarte.</div>
                                    <div style={{height:'10px'}}/>
                                    <TextFieldMultiline title='Observaciones (obligatorio)' id='presu-observaciones'/>
                                </ThemeProvider>
                            </div>
                        </DialogContent>
                        : <DialogContent>
                            <div style={{margin:'20px'}}>
                                <div>Para poder solicitar un presupuesto debes estar registrado.</div>
                                <div style={{margin:'10px'}}><ThemeProvider theme={resaltTheme}><RoundButton text="INICIAR SESIÓN" endIcon={<LoginOutlined/>} onClick={async () => {
                                    navigate("/login");
                                    return true;
                                }}/></ThemeProvider></div>
                            </div>
                        </DialogContent>
                    }
                    {userr != null
                        ? <DialogActions>
                            <ThemeProvider theme={resaltTheme}>
                                <RoundButton text="Cancelar" onClick={async () => {
                                    setSendPresu(false);
                                    return false;
                                }}/>
                                <RoundButton text="Enviar" endIcon={<SendOutlined/>} onClick={async () => {
                                    const sendPresu = httpsCallable(functions, 'sendPresupuesto');

                                    const result = await sendPresu({
                                        fields: fields.map((field) => {
                                            return {
                                                id: field.getId(),
                                                title: field.getTitle(),
                                                value: field.getValue()
                                            };
                                        }),
                                        observaciones: document.getElementById('presu-observaciones').value
                                    });

                                    console.log(result);
                                        
                                    if (result.data) {
                                        setMsg({
                                            type: 'success',
                                            message: 'Se ha enviado el presupuesto correctamente.'
                                        });
                                    } else {
                                        setMsg({
                                            type: 'error',
                                            message: 'Algo ha ido mal mientras se enviaba el presupuesto.'
                                        });
                                    }

                                    setSendPresu(false);
                                    return true;
                                }}/>
                            </ThemeProvider>
                        </DialogActions>
                        : null
                    }
                </Dialog>
            </div>
            <div style={{height:'20px'}}/>
        </div>;
        }
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}