import { StyleSheet, Text, View } from "@react-pdf/renderer";

const borderColor = '#36454f';
const styles = StyleSheet.create({
    tableContainer: {
        //flexDirection: 'row',
        //flexWrap: 'wrap',
        borderWidth: 1,
        borderTopWidth: 2,
        borderColor: '#36454f',
        margin: 0
    },
    row: {
        flexDirection: 'row',
        borderBottomColor: '#36454f',
        borderBottomWidth: 1,
        //alignItems: 'flex-start',
        //fontStyle: 'bold',
    },
    container: {
        flexDirection: 'row',
        borderBottom: `2px solid #36454f`,
        backgroundColor: '#a1b0bf',
        //alignItems: 'center',
        //height: 24,
        textAlign: 'center',
        //fontStyle: 'bold',
        flexGrow: 1,
    },
    standardCell: {
        //width: '10%',
        borderColor: borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
    },
    primaryText: {
        fontSize: '8px'
    }
});

/*
    Items:
        [  
            ""RowData"""
        ]
    
    Items variable is an array of RowData structured data.
*/

const Table = ({header=[{style: null, lable: "", secondary:""}], items=[[{style:null, lable:"", secondary:""}]]}) => {
    return <View style={styles.tableContainer}>
        <TableHeader header={header}/>
        {items.map((rowData, index) => {
            return <TableRow key={index} rowData={rowData}/>
        })}
    </View>
}

/*
    Header data:
        [
            {
                style: #It will be provided by a StyleSheet.create() function
                lable: #A String that contain the title/lable for the title of this table column
                secondary: #A secont string, for more details
            },
            ...
        ]
*/

const TableHeader = ({header}) => {
    return <View style={styles.container}>
        {header.map(((headerCell, index) => {
            return <View key={index} style={headerCell.style===null?{
                width: `${(100/header.length)}%`,
                borderLeft: `1px solid ${borderColor}`,
                borderRight: `1px solid ${borderColor}`,
                textAlign: 'right'
            }:headerCell.style}>{headerCell.lable}
                <Text style={styles.primaryText}>{headerCell.lable}</Text>
                <Text>{headerCell.secondary}</Text>
            </View>
        }))}
    </View>
}

/*
    Row data:
        [
            {
                style: #It will be provided by a StyleSheet.create() function
                lable: #String that is used to store the lable to be displayed on the desired column in the current row
                secondary: #Another stirng
            }
        ]
*/

const TableRow = ({rowData}) => {
    return <View style={styles.row}>
        {rowData.map((cellData, index) => {
            return <View key={index} style={cellData.style===null?{
                width: `${(100/rowData.length)}%`,
                borderColor: borderColor,
                borderWidth: '1px',
                borderStyle: 'solid',
                textAlign: 'right'
            }:cellData.style}>{cellData.lable}
                <Text style={styles.primaryText}>{cellData.lable}</Text>
                <Text>{cellData.secondary}</Text>
            </View>
        })}
    </View>
}

export default Table;