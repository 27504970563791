import { Divider } from "@mui/material";
import PageBoxButton from "../components/page-box";
import { useNavigate } from "react-router-dom";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import SchoolIcon from '@mui/icons-material/School';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SourceIcon from '@mui/icons-material/Source';
import AuthProvider from "../implementation/auth-provider";
import { useState } from "react";
import LoadingBarApp from "../implementation/loading";
import { ReceiptLongOutlined, ReceiptOutlined, SchoolOutlined, Settings } from "@mui/icons-material";
import Navbar from "../implementation/navbar";

export default function Dashboard() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    if (state === 1) {
        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div style={{margin:'5px'}}>
                <h1 className="page-title-p">
                    Panel de control
                </h1>
                <Divider/>
                <div style={{display:'flex', flexWrap:'wrap', marginTop:'5px'}}>
                    <PageBoxButton onClick={() => {navigate("account")}} icon={<SourceIcon style={{color:"white"}}/>} title="Datos personales y facturación" description="Revisa tus datos personales y de facturación. O solicita un cambio de estos."/>
                    <PageBoxButton onClick={() => {window.open("https://campus.bitmasters.es/dashboard/profile")}} icon={<AccountCircleIcon style={{color:"white"}}/>} title="Perfil público" description="Revisa y configura los datos públicos para nuestra academia de programación."/>
                    <PageBoxButton onClick={() => {navigate('payments')}} icon={<ReceiptLongOutlined style={{color:"white"}}/>} title="Pagos realizados" description="Revisa todos los pagos que hayas realizado en nuestras platafromas."/>
                    <PageBoxButton onClick={() => {navigate('budgets')}} icon={<ReceiptOutlined style={{color:"white"}}/>} title="Presupuestos solicitados" description="Revisa todos los presupuestos que hayas solicitado, activos o antiguos."/>
                </div>
                <div style={{height:'20px'}}/>
                <Divider/>
                <h1 className="title middle">
                    Otras plataformas de BitMasters
                </h1>
                <Divider/>
                <div style={{display:'flex', flexWrap:'wrap', marginTop:'5px'}}>
                    <PageBoxButton onClick={() => {window.open("https://academy.bitmasters.es/")}} icon={<SchoolOutlined style={{color:"white"}}/>} title="Academia" description="Acceda a nuestra academia de programación, diseñada para niñas y niños de 12 a 18 años."/>
                    <PageBoxButton onClick={() => {window.open("https://campus.bitmasters.es/")}} icon={<SchoolOutlined style={{color:"white"}}/>} title="Campus" description="Acceda al campus desde el cual podrás cursar los estudios que ofrecemos en nuestra academia."/>
                </div>
            </div>
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}