import { useNavigate, useParams } from "react-router-dom";
import LoadingBarApp from "../implementation/loading";
import { useState } from "react";
import Navbar from "../implementation/navbar";
import { Divider, ThemeProvider } from "@mui/material";
import AuthProvider from "../implementation/auth-provider";
import { DrawPaymentItem, Payment } from "../classes/payment";
import { useMediaQuery } from "react-responsive";
import { CustomDividerHorizontal, RoundButton } from "../implementation/customUi";
import { userFactura } from "../firebase/firebase";
import { CurrencyExchangeOutlined, RequestQuoteOutlined } from "@mui/icons-material";
import { theme } from "../style/theme";

export default function PaymentView() {
    const {pid} = useParams();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [payment, setPayment] = useState(null);
    const [loading, setLoading] = useState(false);
    const [factura, setFactura] = useState(false);

    const bigScreen = useMediaQuery({
        query: '(min-width: 900px)'
    });
    const middleScreen = useMediaQuery({
        query: '(min-width: 540px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 370px)'
    });

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getPayment() {
        try {
            const facturaData = userFactura(userr.uid);
            setPayment(await Payment.fromFirestore(userr.uid, pid, true));
            setFactura(facturaData);
        } catch (e) {
            console.error(e);
            setPayment(false);
        }
    }

    if (state === 1) {
        if (payment == null && !loading) {
            getPayment();
            return <div>
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <LoadingBarApp/>
            </div>;
        }

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div>
                <div style={{margin:'5px'}}><h1 className={`title ${bigScreen? 'big' : middleScreen? 'middle' : smallScreen? 'small' : 'very-small'}`}>
                    ID de pago: {pid}
                </h1></div>
                <Divider/>
                {payment
                    ? <div style={{margin:'5px'}}>
                        <h1 style={{margin:0}}>Resumen del pago:</h1>
                        <div style={{marginLeft:'30px'}}>
                            {payment.getItems().map((item, index) => {
                                return <DrawPaymentItem key={index} item={item}/>;
                            })}
                            <CustomDividerHorizontal color="grey" height="1px" extraMargin="10px"/>
                            <h1 style={{margin:'0px 10px 0px 0px', textAlign:'end'}}>Total: {(payment.getTotal()/100).toFixed(2)}€</h1>
                        </div>
                        <div style={{height:'10px'}}/>
                        <ThemeProvider theme={theme}>
                            <div style={{display:'flex', margin:'0px 15px'}}>
                                <RoundButton text="Factura" endIcon={<RequestQuoteOutlined/>} onClick={async () => {
                                    navigate('factura')
                                    return false;
                                }}/>
                                <div style={{width:'15px'}}/>
                                <RoundButton text="Devolución" endIcon={<CurrencyExchangeOutlined/>} onClick={async () => {
                                    return false;
                                }}/>
                            </div>
                        </ThemeProvider>
                    </div>
                    : <h1 className={`title ${bigScreen? 'middle' : 'small'}`}>Parece que no hay ningún apgo con este ID.</h1>
                }
            </div>
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}>
            <LoadingBarApp/>
    </AuthProvider>
}