import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/LogoFullBM.png';
import Navbar from '../implementation/navbar';
import { useEffect, useState } from 'react';
import AuthProvider from '../implementation/auth-provider';
import LoadingBarApp from '../implementation/loading';

import textLogo from '../assets/img/TextBM.png';
import Carousel from 'react-bootstrap/Carousel';
import { ThemeProvider } from '@mui/material';
import { CustomDividerHorizontal, IconText, RoundButton } from '../implementation/customUi';

import '../style/carrousel.css';
import { useMediaQuery } from 'react-responsive';

import webdevside from '../assets/img/stock/webdev_side.jpg';

import learning from '../assets/img/stock/learning.jpg';
import { CalculateOutlined, CategoryOutlined, CloudOutlined, CodeOutlined, DevicesOtherOutlined, LaunchOutlined, SchoolOutlined, StoreOutlined, WebOutlined } from '@mui/icons-material';
import { resaltTheme, secondaryTheme, theme } from '../style/theme';

import { useTranslation } from "react-i18next";

export default function HomePage() {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [windowHeigh, setWindowHeig] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [fakeState, setFakeState] = useState(false);

    const isLaptop = useMediaQuery({
        query: '(min-width: 1000px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 530px)'
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowHeig(window.innerHeight);
            setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener("resize", handleResize);
    
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        setCurrentState(1);
    }

    const bitmastersData = [
        [<div>
            <div style={{fontSize: isLaptop? '' : smallScreen? '36px' : '30px'}}>{t('development.presentation.title')} <span style={{lineHeight: isLaptop? '36px' : smallScreen? '36px' : '30px', display:'inline-block', verticalAlign:'text-top'}}><img src={textLogo} height={isLaptop? '36px' : smallScreen? '30px' : '27px'} style={{maxWidth: `${windowWidth-75}px`, objectFit:'scale-down'}}/></span></div>
            <div style={{height: '20px'}}/>
            <div className='carousel-extra-info no-center-align'>
                {t('development.presentation.info')}
            </div>
            <div style={{height: '20px'}}/>
            <div className='carousel-extra-info important-text no-center-align'>
                {t('development.presentation.to')}
            </div>
            <div style={{height: '20px'}}/>
            <ThemeProvider theme={resaltTheme}>
                <RoundButton text="MÁS INFORMACIÓN" endIcon={<LaunchOutlined/>} onClick={async () => {
                    return false;
                }}/>
            </ThemeProvider>
        </div>, webdevside],
        [<div>
            <div style={{fontSize: isLaptop? '' : smallScreen? '36px' : '30px'}}>{t('learning.presentation.title')} <span style={{lineHeight: isLaptop? '36px' : smallScreen? '36px' : '30px', display:'inline-block', verticalAlign:'text-top'}}><img src={textLogo} height={isLaptop? '36px' : smallScreen? '30px' : '27px'} style={{maxWidth: `${windowWidth-75}px`, objectFit:'scale-down'}}/></span></div>
            <div style={{height: '20px'}}/>
            <div className='carousel-extra-info no-center-align'>
                {t('learning.presentation.info')}
            </div>
            <div style={{height: '20px'}}/>
            <div className='carousel-extra-info important-text no-center-align'>
                {t('learning.presentation.to')}
            </div>
            <div style={{height: '20px'}}/>
            <ThemeProvider theme={resaltTheme}>
                <RoundButton href="https://academy.bitmasters.es" text="MÁS INFORMACIÓN" endIcon={<LaunchOutlined/>} onClick={async () => {
                    return false;
                }}/>
            </ThemeProvider>
        </div>, learning]
    ];

    const iconSize = "70px";

    if (state === 1) {
        console.log(`The pathname is ${window.location.href}`);
        if (window.location.href.includes('#')) {
            const splitedPath = window.location.href.split('#');
            const elemid = splitedPath[splitedPath.length-1];

            console.log(`The element id that is pointed is: ${elemid}`);

            const element = document.getElementById(elemid);

            if (element === null) {
                setTimeout(() => {
                    setFakeState(!fakeState);
                }, 100);
            }

            element?.scrollIntoView({
                behavior:'smooth'
            });
        }

        return <div>
            <Navbar userId={userr != null? userr.uid : null}/>
            <div style={{height:'64px', backgroundColor:'#708090'}}/>
            <Carousel className="home-page-carrousel" controls={!smallScreen} style={{minHeight:`${window.innerHeight.toFixed(2)-64}px`, color:'#39444f'}} interval={smallScreen? 5000 : null} pause="hover">
                {bitmastersData.map((data, index) => {
                    return <Carousel.Item key={index}>
                        {isLaptop
                            ? <div className="carousel-course-region" style={{"--minHeighDynamic": `${window.innerHeight.toFixed(2)-64}px`}}>
                                <div className="carousel-course-presentation">
                                    {data[0]}
                                </div>
                                <div style={{marginLeft:'auto', width:'50%'}}>
                                    <img src={data[1]} width={'100%'} className="carousel-presentation-img"/>
                                </div>
                            </div>
                            : <div className="carousel-course-region no-flex">
                                <img src={data[1]} width={'100%'} className="carousel-presentation-img no-flex"/>
                                <div className="carousel-course-presentation no-flex" style={smallScreen
                                    ? {}
                                    : {padding:'40px 50px'}
                                }>
                                    {data[0]}
                                </div>
                            </div>
                        }
                    </Carousel.Item>;
                })}
            </Carousel>
            <div style={{height:'50px'}}/>
            <div id='bitmasters-section' style={{margin:'10px', textAlign:'justify'}}>
                <div>
                    <h1 className={`title ${isLaptop?'':smallScreen?'big':'middle'}`} style={{color:'#39444f'}}><span style={{lineHeight: smallScreen?'40px':'30px', display:'inline-block'}}><img src={textLogo} height={smallScreen?'40px':'30px'} style={{maxWidth: `${windowWidth-75}px`, objectFit:'scale-down'}}/></span> somos una pequeña empresa con <span style={{fontWeight:700}}>grandes profesionales</span>.</h1>
                    <div style={{marginTop:'20px', fontSize:'22px'}}>
                        <div>Todo lo que puedas necesitar al alcance de tu mano. Desde los cursos de programación que necesitan sus hijos hasta los programas y servicios más innovadores para sus clientes.</div>
                    </div>
                    <div style={{marginTop:'10px', fontSize:'22px'}}>
                        <div>Somos una empresa nacida en Barcelona y que gracias a nuestros conocimientos hemos podido abrirnos camino en el grande mundo de la informática y tecnologia.</div>
                    </div>
                </div>
                <CustomDividerHorizontal color="grey" height="1px" extraMargin="5px" horizontalMargin="40px"/>
                <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', gap:'60px'}}>
                    <IconText icon={<SchoolOutlined style={{fontSize:iconSize}}/>} text="Academia de programación" route="/academy"/>
                    <IconText icon={<CodeOutlined style={{fontSize:iconSize}}/>} text="Desarrollo de aplicaciones para servicios internos" route="/development"/>
                    <IconText icon={<StoreOutlined style={{fontSize:iconSize}}/>} text="Desarrollo de comercios online" route="/ecommerce"/>
                    <IconText icon={<WebOutlined style={{fontSize:iconSize}}/>} text="Desarrollo de aplicaciones web" route="/webdevelopment"/>
                    <IconText icon={<DevicesOtherOutlined style={{fontSize:iconSize}}/>} text="Desarrollo de aplicaciones multiplataforma" route="/appdevelopment"/>
                    <IconText icon={<CloudOutlined style={{fontSize:iconSize}}/>} text="Su nuve privada para gestionar sus archivos" route="/cloud"/>
                    <IconText icon={<CategoryOutlined style={{fontSize:iconSize}}/>} text="Productos ya funcionales y adaptables" route="/products"/>
                    <IconText icon={<CalculateOutlined style={{fontSize:iconSize}}/>} text="Calcula un precio aproximado para ti" route="/calculator"/>
                </div>
                <CustomDividerHorizontal color="grey" height="1px" extraMargin="5px" horizontalMargin="40px"/>
            </div>
            <div style={{height:'20px'}}/>
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}