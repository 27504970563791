import { useNavigate, useParams } from "react-router-dom";
import LoadingBarApp from "../implementation/loading";
import { useState } from "react";
import Navbar from "../implementation/navbar";
import { CircularProgress, Divider, IconButton, ThemeProvider } from "@mui/material";
import AuthProvider from "../implementation/auth-provider";
import { DrawPaymentItem, Payment } from "../classes/payment";
import { useMediaQuery } from "react-responsive";
import { CustomDividerHorizontal, RoundButton } from "../implementation/customUi";
import { userFactura } from "../firebase/firebase";
import { CurrencyExchangeOutlined, DownloadOutlined, RequestQuoteOutlined } from "@mui/icons-material";
import { theme } from "../style/theme";
import { facturaGeneration } from "../implementation/pdf-gen";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

export default function FacturaView() {
    const {pid} = useParams();

    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    const [factura, setFactura] = useState(null);
    const [downloadFactura, setDownloadFactura] = useState(null);

    const bigScreen = useMediaQuery({
        query: '(min-width: 900px)'
    });
    const middleScreen = useMediaQuery({
        query: '(min-width: 540px)'
    });
    const smallScreen = useMediaQuery({
        query: '(min-width: 370px)'
    });

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        navigate("/login");
    }

    async function getFactura() {
        setFactura(await facturaGeneration(userr.uid, pid));
        setDownloadFactura(await facturaGeneration(userr.uid, pid));
    }

    if (state === 1) {
        if (factura === null) {
            getFactura();
        } else {
            //console.log(factura.props);

            return <div className="full-height">
                <Navbar userId={userr.uid}/>
                <div style={{height:'64px'}}/>
                <div className="full-height with-navbar" style={{margin:'5px'}}>
                    <div style={{display:'flex'}}>
                        <div style={{marginLeft:'auto'}}>
                            {downloadFactura === null
                                ? <CircularProgress/>
                                : <PDFDownloadLink document={downloadFactura} fileName={`FacturaBitMasters_${pid}`}>
                                    {({ blob, url, loading, error }) => (loading ? <CircularProgress/> : <IconButton><DownloadOutlined/></IconButton>)}
                                </PDFDownloadLink>
                            }
                        </div>
                    </div>
                    <PDFViewer style={{width:"-webkit-fill-available", height:"calc(100% - 45px)"}} showToolbar={false}>
                        {factura}
                    </PDFViewer>
                </div>
            </div>;
        }

        return <div>
            <Navbar userId={userr.uid}/>
            <div style={{height:'64px'}}/>
            <div style={{margin:'5px'}}>
                <LoadingBarApp/>
                <div style={{height:'10px'}}/>
                <h1 className="title" style={{textAlign:'center'}}>Creando su factura</h1>
            </div>
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}>
            <LoadingBarApp/>
    </AuthProvider>
}