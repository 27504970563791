import { Document, Font, Image, Page, Rect, StyleSheet, Text, View } from "@react-pdf/renderer";
import { Payment } from "../classes/payment";

import logo from '../assets/img/LogoTextBM.png';
import { useSSR } from "react-i18next";
import { useState } from "react";

import { User } from '../classes/user';

import UbuntuRegular from '../assets/fonts/Ubuntu/Ubuntu-Regular.ttf';
import UbuntuMedium from '../assets/fonts/Ubuntu/Ubuntu-Medium.ttf';
import UbuntuBold from '../assets/fonts/Ubuntu/Ubuntu-Bold.ttf';
import { auth } from "../firebase/firebase";
import Table from "./pdf-assets/table";

Font.register({
    family: 'Ubuntu',
    fonts: [
        {
            src: UbuntuRegular
        },
        {
            src: UbuntuMedium,
            fontWeight: "medium"
        },
        {
            src: UbuntuBold,
            fontWeight: "bold"
        }
    ]
});

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Ubuntu',
        backgroundColor: "#FFFFFF",
        padding: 30,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontWeight: "bold",
    },
    important: {
        fontFamily: 'Ubuntu',
        fontWeight: "bold",
        fontSize: '14px'
    },
    titleImportant: {
        fontFamily: 'Ubuntu',
        fontWeight: "bold",
        fontSize: '20px'
    },
    pageSection: {
        margin: 10,
        padding: 10,
        fontSize: 12
    },
    section: {
      display: "flex",
      flexDirection: "row",
      margin: 10,
      padding: 10,
    },
    parragraph: {
      fontSize: 12,
      textAlign: "justify",
      lineHeight: 1.5,
      margin: 10,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 20,
      left: 0,
      right: 20,
      textAlign: "right",
      color: "grey",
    },
    headerSectionLeft: {
        position: "absolute",
        top:'10px',
        left: 0,
        width: "40%",
        marginRight:'auto',
        fontSize: '12px'
    },
    headerSectionRight: {
        position: "relative",
        top:'10px',
        right: 0,
        width: "40%",
        marginLeft:'auto'
    },
    horizontalDivider: {
        height: '1px',
        backgroundColor: 'grey'
    }
});

const borderColor = '#36454f';

export async function facturaGeneration(userId, paymentId) {
    const payment = await Payment.fromFirestore(userId, paymentId, true);

    const client = await User.fromFirestoreWithFiscalData(userId);

    const tableItems = [];

    const items = payment.getItems();
    console.log(items);

    await Promise.all(items.map(item => {
        console.log("Adding payment info to table");
        tableItems.push([
            {
                style: {
                    width: `20%`,
                    borderLeft: `1px solid ${borderColor}`,
                    borderRight: `1px solid ${borderColor}`,
                    textAlign: 'left',
                    height: '100%',
                    padding: '5px'
                },
                lable: item.getId(),
                secondary: item.getName(),
            },
            {
                style: {
                    width: `30%%`,
                    borderLeft: `1px solid ${borderColor}`,
                    borderRight: `1px solid ${borderColor}`,
                    textAlign: 'right',
                    height: '100%',
                    padding: '5px'
                },
                lable: `${((item.getPriceUnit()/100)/1.21).toFixed(2)}€`,
                secondary: `${(item.getPriceUnit()/100).toFixed(2)}€`
            },
            {
                style: {
                    width: `20%%`,
                    borderLeft: `1px solid ${borderColor}`,
                    borderRight: `1px solid ${borderColor}`,
                    textAlign: 'right',
                    height: '100%',
                    padding: '5px'
                },
                lable: `${item.getQuantity()} ${item.getQuantity()!=1?'uds':'ud'}.`,
                secondary: `${item.getDiscount()!=0?((item.getTotalPrice()/100)/(item.getDiscount()/100)).toFixed(2):(0).toFixed(2)}%`
            },
            {
                style: {
                    width: `30%%`,
                    borderLeft: `1px solid ${borderColor}`,
                    borderRight: `1px solid ${borderColor}`,
                    textAlign: 'right',
                    height: '100%',
                    padding: '5px'
                },
                lable: `${((item.getRealPrice()/100)/1.21).toFixed(2)}€`,
                secondary: `${(item.getRealPrice()/100).toFixed(2)}€`
            },
        ]);
    }));

    console.log(tableItems);

    return <Document title={`Factura_${payment.getId()}`} author="BitMasters">
        <Page id="factura-to-print" size="A4" style={styles.page}>
            <View fixed>
                <View style={styles.headerSectionLeft}>
                    <Text style={{fontSize: '24px'}}>FACTURA</Text>
                    <Text>{payment.getId()}</Text>
                </View>
                <View style={styles.headerSectionRight}>
                    <Image src={logo}/>
                </View>
            </View>
            <View style={styles.pageSection}>
                <View style={{marginTop:'20px'}}/>
                <View>
                    <Text>Fecha de emision: {payment.getDateString()}</Text>
                    <View style={{marginTop:'10px'}}/>
                    <View style={{display:'flex', flexDirection:'row'}}>
                        <View>
                            <Text style={styles.important}>Factura de:</Text>
                            <Text>Marc Planas Almirall (BitMasters)</Text>
                            <View style={{height:'5px'}}/>
                            <Text>Carrer d'Arenys de Mar, 35</Text>
                            <Text>08225 Terrassa, Barcelona</Text>
                            <View style={{height:'5px'}}/>
                            <Text>691 21 96 92</Text>
                            <Text>info@bitmasters.es</Text>
                        </View>
                        <View style={{width:'20px'}}/>
                        <View>
                            <Text style={styles.important}>Factura para:</Text>
                            <Text>{client.getFiscalData().getName()}</Text>
                            <View style={{height:'5px'}}/>
                            <Text>{client.getFiscalData().getAddress()}</Text>
                            <View style={{height:'5px'}}/>
                            <Text>{client.getPhone()}</Text>
                            <Text>{auth.currentUser.email}</Text>
                        </View>
                    </View>
                    <View style={{marginTop:'15px'}}/>
                    <Text style={styles.titleImportant}>Resumen del pago</Text>
                    <View style={{height:'10px'}}/>
                    <View>
                        <Table
                            header={[
                                {
                                    style: {
                                        width: `20%`,
                                        borderLeft: `1px solid ${borderColor}`,
                                        borderRight: `1px solid ${borderColor}`,
                                        textAlign: 'left',
                                        height: '100%',
                                        padding: '5px'
                                    },
                                    lable: "Referencia",
                                    secondary: "Descripción"
                                },
                                {
                                    style: {
                                        width: `30%%`,
                                        borderLeft: `1px solid ${borderColor}`,
                                        borderRight: `1px solid ${borderColor}`,
                                        textAlign: 'right',
                                        height: '100%',
                                        padding: '5px'
                                    },
                                    lable: "Precio ud. (sin IVA)",
                                    secondary: "Precio ud. (con IVA)"
                                },
                                {
                                    style: {
                                        width: `20%%`,
                                        borderLeft: `1px solid ${borderColor}`,
                                        borderRight: `1px solid ${borderColor}`,
                                        textAlign: 'right',
                                        height: '100%',
                                        padding: '5px'
                                    },
                                    lable: "Cantidad",
                                    secondary: "Descuento"
                                },
                                {
                                    style: {
                                        width: `30%%`,
                                        borderLeft: `1px solid ${borderColor}`,
                                        borderRight: `1px solid ${borderColor}`,
                                        textAlign: 'right',
                                        height: '100%',
                                        padding: '5px'
                                    },
                                    lable: "Precio total (sin IVA)",
                                    secondary: "Precio total (con IVA)"
                                },
                            ]}
                            items={tableItems}
                        />
                        <View style={{height:'10px'}}/>
                        <View style={{textAlign:'right'}}>
                            <Text>{`Total sin IVA: ${((payment.getTotal()/100)/1.21).toFixed(2)}€`}</Text>
                            <Text>{`Total IVA: ${((payment.getTotal()/100).toFixed(2)-((payment.getTotal()/100)/1.21).toFixed(2)).toFixed(2)}€`}</Text>
                            <Text>{`Total con IVA: ${(payment.getTotal()/100).toFixed(2)}€`}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <Text style={styles.pageNumber} render={({pageNumber, totalPages}) => (
                `Pg.${pageNumber}/${totalPages}`
            )} fixed/>
        </Page>
    </Document>;
}