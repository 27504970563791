import { useSearchParams } from "react-router-dom";

import logo from '../assets/img/LogoFullBM.png';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import handleResetPassword from "../auth_modeules/resetPassword";
import HandleResetPassword from "../auth_modeules/resetPassword";

import '../style/login.css';
import { auth } from "../firebase/firebase";

export default function AuthPage() {
    const [searchParams, setSearchParams] = useSearchParams();

    const mode = searchParams.get('mode');
    const actionCode = searchParams.get('oobCode');

    return <div style={{margin:'20px', display:'flex', justifyContent:'center'}}>
        <div>
            <div style={{ display:'flex', justifyContent:'center'}}><img src={logo} width="300px"/></div>
            <div>
                <div>Mode: {mode}</div>
                <div>Code: {actionCode}</div>
                <div style={{height:'20px'}}/>
                <div className="login-form-card">
                    {mode === "resetPassword"
                        ? <div>
                            <div className="login-title">CAMBIAR CONTRASEÑA</div>
                            <HandleResetPassword auth={auth} actionCode={actionCode}/>
                        </div>
                        : <div></div>
                    }
                </div>
                
            </div>
        </div>
    </div>;
}