import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/LogoFullBM.png';
import Navbar from '../implementation/navbar';
import { useState } from 'react';
import AuthProvider from '../implementation/auth-provider';
import LoadingBarApp from '../implementation/loading';

export default function NotFoundPage() {
    const navigate = useNavigate();

    const [state, setCurrentState] = useState(0);
    const [userr, setUser] = useState(null);

    function handleOnUserLoggedIn(user) {
        setUser(user);
        setCurrentState(1);
    }

    function handleUserNotRegistered(user) {
        navigate("/dashboard/account");
    }

    function handleUserNotLoggedIn() {
        setCurrentState(1);
    }

    if (state === 1) {
        return <div>
            <Navbar userId={userr != null? userr.uid : null}/>
            <div style={{height:'64px'}}/>
            <div style={{display:'flex', justifyContent:'center', margin:"20px"}}>
                <img src={logo} width={"200px"}></img>
            </div>
        </div>;
    }

    return <AuthProvider
        onUserLoggedIn={handleOnUserLoggedIn}
        onUserNotRegistered={handleUserNotRegistered}
        onUserNotLoggedIn={handleUserNotLoggedIn}
    >
        <LoadingBarApp/>
    </AuthProvider>
}